<template>
  <div class="bg">
    <div class="title_div">业务流程</div>

    <div style="height: 4vh"></div>
    <div v-for="(item, index) in processList" :key="index">
      <div class="every_option">
        <!--卡片头部-->
        <div
          class="option_top"
          v-if="pagetype1 == 1"
          @click="chooseOption(item)"
        >
          <div
            style="
              color: #3c98e5;
              font-size: 4.6vw;
              margin-left: 16vw;
              white-space: nowrap;
            "
          >
            {{ item.name }}
          </div>
          <div
            style="
              color: #9e9b9e;
              font-size: 3vw;
              display: flex;
              align-items: center;
              margin-right: 4vw;
            "
          >
            <div
              style="
                margin-right: -1vw;
                font-size: 1vw;
                white-space: nowrap;
                transform: scale(0.9);
              "
            >
              {{ item.enName }}
            </div>
          </div>
          <!-- <img v-if="item.is_select==true"  src="@/assets/icon_2/xiangshang.png" style="height:0.5vh;">
          <img v-else   src="@/assets/icon_2/xiangxia.png" style="height:0.5vh;"> -->

          <!--定位图标-->
          <div class="blue_circle">
            <img :src="getUrl(index)" style="height: 4vw; width: 4vw" />
          </div>
        </div>
        <!--卡片点-->
        <!-- <div v-if="item.is_select==true" style="display:flex;justify-content:flex-end;">
        <div style="border:1px dashed #A19EA2;width:80vw;"></div>
      </div> -->
        <!--卡片底部-->
        <!-- <div  v-for="(item2,index2) in item.answers" :key="index2" >
        <div v-if="item.is_select==true" style="padding-top:1.6vh;padding-bottom:1.8vh;padding-right:5vw;">
          <div style="display:flex;align-items:center;">
              <img src="@/assets/icon_2/tiwen.png" style="height:5vw;margin-left:5.5vw;margin-right:3vw;">
              <div style="display:flex;justify-content:space-between;width:80vw;">
                <div style="color:#9E9B9E;font-size:3.5vw;">{{item2.question}}</div>
              </div>
              <div style="color:#9E9B9E;font-size:3.5vw;white-space: nowrap;">{{item2.createTime}}</div>
            </div>

             <div style="display:flex;align-items:center;margin-top:0.8vh;">
              <img src="@/assets/icon_2/huida.png" style="height:5vw;margin-left:5.5vw;margin-right:3vw;">
              <div style="color:#9E9B9E;font-size:3.5vw;">{{item2.answer}}</div>
            </div>
          </div>
        </div> -->
      </div>

      <div v-if="flag && index !== 6" style="display: flex">
        <div
          style="
            background-color: #3c98e5;
            height: 4vh;
            width: 0.6vw;
            margin-left: 10vw;
          "
        ></div>
      </div>
    </div>

    <!-- <div v-if="pagetype1 == 2">
    
    </div> -->
    <!--底部tab栏-->
    <Tabbar2
      :choose_index="1"
      :user_type2="user_type2"
      :type="pagetype"
    ></Tabbar2>
  </div>
</template>

<script>
import Tabbar2 from "@/components/Tabbar2";

export default {
  components: {
    Tabbar2,
  },
  data() {
    return {
      flag: true,
      pagetype: 1,
      pagetype1: 1,
      user_type2: false,
      processList: [], //业务流程表
      icon_List: [
        { imgURL: require("@/assets/icon_2/tanpanliucheng.png") },
        { imgURL: require("@/assets/icon_2/zhiguanxinxi.png") },
        { imgURL: require("@/assets/icon_2/rukuliucheng.png") },
        { imgURL: require("@/assets/icon_2/yunying.png") },
        { imgURL: require("@/assets/icon_2/dianshang.png") },
        { imgURL: require("@/assets/icon_2/jiekuan.png") },
        { imgURL: require("@/assets/icon_2/tuihuo.png") },
      ],
      newsList: [],
     
    };
  },
  mounted() {
    this.getProcessList();
  },
  methods: {
    //选择队列
    chooseOption(item) {
      this.flag = !this.flag;
      console.log(item,"aaaaaaaaaaa")
      console.log(item.path,"vvvvvvvvvvvvv")
      this.$router.push(`./${item.path}`);
      this.choose_item = item;
      // this.pagetype1 = 2;
     
    },

    getProcessList() {
      this.$axios.get(this.$api.getProcessList, {}).then((res) => {
         console.log(res,"ggggggggg");
       this.processList =  res.data.list.map(ele=>({
        ...ele,
        is_select : false,
        path : `business/${ele.id}`

        }))
        console.log(this.processList,"bbbbbbbbbbbb");
      })
      
    },
    //业务流程图标
    getUrl(index) {
      return this.icon_List[index].imgURL;
    },
  },
};
</script>
<style scoped>
.title_div {
  height: 8vh;
  background-image: linear-gradient(#3f98eb, #006ed5);
  /* background: #3F98EB; */
  line-height: 8vh;
  color: #ffffff;
  text-align: center;
  font-size: 4.6vw;
}
.every_option {
  width: 96vw;
  margin-left: 2vw;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 10px 20px 0 rgba(116, 114, 114, 0.2);
  position: relative;
}
.bg {
  min-height: 100vh;
  background: #faf7f8;
}
.option_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1vh 0;
}
.blue_circle {
  background-color: #3f98eb;
  height: 7vw;
  width: 7vw;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1.2vh;
  left: 4.8vw;
}
</style>
